<template>
  <div>
    <brand-information-skeleton v-if="!isLoading" />
    <div v-else>
      <portal to="body-top">
        <h4 class="font-weight-bolder font-medium-5 text-center mb-1">
          {{ brand['name'] }}
        </h4>
      </portal>
      <div class="rounded">
        <h4 class="font-weight-bolder mb-1">
          {{ $t('Brand Information') }}
        </h4>
        <b-col
          v-for="({key, label}) in brandInformationColumns"
          :key="key"
          md="6"
          class="d-flex"
        >
          <b-col
            md="6"
            class="mb-1 font-weight-bolder"
            style="color: #646464"
          >
            {{ $t(label) }}
          </b-col>
          <b-col
            md="6"
            style="color: #646464"
          >
            {{ brand[key] === true ? 'Active' : brand[key] === false ? 'Not Active' : brand[key] }}
          </b-col>
        </b-col>
      </div>
    </div>
  </div>
</template>
<script>
import { BCol } from 'bootstrap-vue'
import BrandInformationSkeleton from '@/views/settings/catalog/brands/components/BrandInformationSkeleton.vue'
import config from '../brandConfig'

export default {
  name: 'BrandInformation',
  components: { BrandInformationSkeleton, BCol },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    brand() {
      return this.$store.state[this.MODULE_NAME].brand
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(() => {
      this.isLoading = true
    })
  },

  setup() {
    const { MODULE_NAME, brandInformationColumns } = config()

    return {
      MODULE_NAME,
      brandInformationColumns,
    }
  },
}
</script>
