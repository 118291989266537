<template>
  <div>
    <portal to="body-top">
      <h4 class="font-weight-bolder font-medium-5 text-center mb-1">
        <b-skeleton
          class="w-25 text-center mx-auto"
          style="width: 100px; height: 40px"
        />
      </h4>
    </portal>
    <div class="rounded">
      <h4 class="font-weight-bolder mb-1">
        {{ $t('Brand Information') }}
      </h4>
      <b-col
        v-for="({key, label}) in brandInformationColumns"
        :key="key"
        md="6"
        class="d-flex"
      >
        <b-col
          md="6"
          class="mb-1 font-weight-bolder"
          style="color: #646464"
        >
          {{ $t(label) }}
        </b-col>
        <b-col
          md="6"
          style="color: #646464"
        >
          <b-skeleton style="width: 100px; height: 40px" />
        </b-col>
      </b-col>
    </div>
  </div>
</template>
<script>

import { BCol, BSkeleton } from 'bootstrap-vue'
import config from '../brandConfig'

export default {
  name: 'BrandInformationSkeleton',
  components: { BCol, BSkeleton },
  setup() {
    const { brandInformationColumns } = config()

    return {
      brandInformationColumns,
    }
  },
}
</script>
